import { createEqualReveal } from "./frameSpecMacros";
import isUndefined from "lodash/isUndefined";
const FALLBACK_IMAGE = "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/framespec-generator-image.jpg?v=1741806583";

const artworkGenerator = (productCategory) => {
  productCategory = productCategory || {};
  return {
    conveyance: "uploaded",
    croppedAndNormalized: {
      dimensionsInPixels: {
        width: 10000,
        height: 6750
      },
      url: productCategory.asset_url || FALLBACK_IMAGE
    },
    customerSpecified: {
      dimensionsInPixels: {
        width: 10000,
        height: 6750
      },
      url: productCategory.asset_url || FALLBACK_IMAGE
    },
    exterior: {
      widthInInches: 10,
      heightInInches: 6.75,
      artworkOpeningWidthInInches: 9.75,
      artworkOpeningHeightInInches: 6.5
    },
    offset: {
      topInInches: "auto",
      leftInInches: "auto"
    },
    artworkId: 1,
    mount: {
      reveal: {}
    }
  };
};

const canvasBaseFrameSpec = () => {
  return {
    mats: [],
    mount: {
      elevated: false,
      name: "WH02",
      reveal: createEqualReveal(0)
    },
    artworks: [],
    moulding: {
      permalink: "heathrow-black-canvas-frame"
    }
  };
};

const digitalUploadBaseFrameSpec = () => {
  return {
    mats: [
      {
        name: "WH02",
        reveal: createEqualReveal(2.5)
      }
    ],
    mount: {
      elevated: false,
      name: "WH02",
      reveal: createEqualReveal(0)
    },
    artworks: [],
    moulding: {
      permalink: "mercer-slim-black-frame"
    }
  };
};


const defaultFrameSpecForCategory = category => {
  category = category || {};
  switch (category.slug) {
    case "canvas":
      return canvasBaseFrameSpec();
    default:
      return digitalUploadBaseFrameSpec();
  }
};

export default function(args) {
  args = args || {};
  let { withArtwork } = args;
  const { productCategory } = args;

  if (isUndefined(withArtwork)) {
    withArtwork = true;
  }

  const newFrameSpec = defaultFrameSpecForCategory(productCategory);

  if (withArtwork) {
    newFrameSpec.artworks.push(artworkGenerator(productCategory));
  }

  return newFrameSpec;
};

